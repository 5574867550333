import { mobile, tablet } from "components/templates/Breakpoints";
import { FC, ReactElement } from "react";
import styled, { css } from "styled-components";
import { convertNewlinesToBreaks } from "utils/string";
import { color } from "../../styles";
import { H2 } from "./Heading";
import { P1, P2 } from "./Paragraph";

interface NewProductIntroProps {
  title?: string;
  description?: string | string[] | ReactElement;
  eyebrow?: string;
  backgroundColor?: string;
  titleColor?: string;
  eyebrowColor?: string;
  pb?: string;
  pt?: string;
  mobPb?: string;
  maxWidth?: string;
  footnote?: string;
  titleFontSize?: [string, string, string];
  titleAlign?: "left" | "center";
}
export const NewProductIntro: FC<React.PropsWithChildren<NewProductIntroProps>> = ({
  title,
  description,
  eyebrow,
  eyebrowColor = color.H100,
  titleColor = color.H100,
  backgroundColor,
  pb,
  pt,
  mobPb,
  maxWidth,
  footnote,
  children,
  titleFontSize,
  titleAlign = "center",
}) => (
  <Wrapper mobPb={mobPb} pb={pb} pt={pt} backgroundColor={backgroundColor}>
    <TextWrap maxWidth={maxWidth}>
      {eyebrow && (
        <Eyebrow color={eyebrowColor} align="center">
          {eyebrow}
        </Eyebrow>
      )}
      <Title align={titleAlign} color={titleColor} $titleFontSize={titleFontSize}>
        {convertNewlinesToBreaks(title)}
      </Title>
      {Array.isArray(description) ? (
        description.map((p) => (
          <P1 align="center" key={p}>
            {p}
          </P1>
        ))
      ) : typeof description === "string" ? (
        <P1 align="center">{convertNewlinesToBreaks(description)}</P1>
      ) : (
        description
      )}
    </TextWrap>
    {children}
    {footnote && (
      <TextWrap>
        <Footnote color={color.B2100} align="center">
          {footnote}
        </Footnote>
      </TextWrap>
    )}
  </Wrapper>
);

const Wrapper = styled.div<{ backgroundColor?: string; pb?: string; mobPb?: string; pt?: string }>`
  padding: 6.875rem;
  padding-top: ${(p) => p.pt};
  padding-bottom: ${(p) => p.pb};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(p) => p.backgroundColor || `${color.B210}`};

  ${tablet} {
    padding-bottom: ${(p) => p.mobPb};
  }

  ${mobile} {
    padding: 4rem 2rem 4rem;
    padding-bottom: ${(p) => p.mobPb};
  }
`;

const TextWrap = styled.div<{ maxWidth?: string }>`
  max-width: ${(p) => p.maxWidth || "58.125rem"};
`;

const Footnote = styled(P2)`
  max-width: 46rem;
`;

const Eyebrow = styled(P1)`
  font-weight: bold;
  padding-bottom: 1rem;
`;

const Title = styled(H2)<{
  $titleFontSize?: [string, string, string];
}>`
  ${(p) =>
    p.$titleFontSize &&
    css`
      font-size: ${p.$titleFontSize[0]};

      ${tablet} {
        font-size: ${p.$titleFontSize[1]};
      }

      ${mobile} {
        font-size: ${p.$titleFontSize[2]};
      }
    `}
`;
