import { mobile, tablet } from "components/templates/Breakpoints";
import { BetterButton } from "components/atoms/BetterButton";
import { ClientOnly } from "components/templates/ClientOnly";
import { useBreakpoints } from "hooks";
import { Link } from "@components/atoms";
import { FC } from "react";
import styled from "styled-components";
import { color } from "../../styles";
import { StaticRoutes } from "@routes";
import Image from "next/image";
import { convertNewlinesToBreaks } from "utils/string";

interface CallbackAppOnlyProps {
  title?: string;
  subtitle?: string;
  buttonText?: string;
}

export const CallbackAppOnly: FC<CallbackAppOnlyProps> = (props) => (
  <ClientOnly style={{ width: "100%" }}>
    <_CallbackAppOnly {...props} />
  </ClientOnly>
);

const _CallbackAppOnly: FC<CallbackAppOnlyProps> = ({
  title = "Is YuLife available without insurance?",
  subtitle = "Yes! You can still take steps to protect your employees’ wellbeing and inspire them to live their best lives. Our global app-only option provides all the benefits of the YuLife app, without the insurance.",
  buttonText = "Find out more",
}) => {
  const { isMobile } = useBreakpoints();

  return (
    <Wrap>
      <Background>
        <TextBlock>
          <Title>{convertNewlinesToBreaks(title)}</Title>
          <Subtitle>{convertNewlinesToBreaks(subtitle)}</Subtitle>
          <Link href={StaticRoutes.insuranceAppOnly} legacyBehavior>
            <BetterButton
              variant="primary"
              text={buttonText}
              width={isMobile ? "100%" : "fit-content"}
            />
          </Link>
        </TextBlock>
        <Img
          src="/static/giraffes/YuLife-Yugi-finish-line.svg"
          alt="Yugi the giraffe, crossing a finish line"
          height={424}
          width={346}
        />
      </Background>
    </Wrap>
  );
};

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8rem;
  flex-grow: 1;

  ${tablet} {
    margin: 4rem;
  }

  @media only screen and (max-width: 50rem) {
    margin: 0;
    padding: 2rem;
  }
`;

const Background = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 58rem;
  background: ${color.S110};
  border-radius: 0.5rem;
  border: 1px ${color.S1100} solid;
  z-index: 0;

  ${tablet} {
    width: 48rem;
    height: auto;
  }

  @media only screen and (max-width: 50rem) {
    border-radius: 0;
    justify-content: center;
    width: 100%;
  }
`;

const TextBlock = styled.div`
  display: flex;
  width: 31.5rem;
  flex-direction: column;
  align-items: flex-start;
  margin: 2.5rem 0 2.5rem 2.5rem;

  @media only screen and (max-width: 50rem) {
    margin: 0;
    padding: 2.5rem;
    width: 100%;
  }
`;

const Title = styled.h2`
  letter-spacing: 0;
  font-size: 2.5rem;
  margin: 0;
  max-width: 30rem;

  ${mobile} {
    width: 100%;
    font-size: 2rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.18rem;
  letter-spacing: 0.075rem;
  margin: 0.938rem 0 2rem;

  @media only screen and (max-width: 72.5rem) {
    width: 25rem;
  }

  @media only screen and (max-width: 50rem) {
    width: auto;
  }

  ${mobile} {
    font-size: 1.1rem;
  }
`;

const Img = styled(Image)`
  position: absolute;
  z-index: 1;
  right: -3rem;
  bottom: -2rem;

  @media only screen and (max-width: 60rem) {
    right: -5rem;
  }

  @media only screen and (max-width: 50rem) {
    display: none;
  }
`;
