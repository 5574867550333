import { FC } from "react";
import { Page } from "../../templates/Page";
import { HeroSection } from "components/organisms/HeroSection";
import { KeyResourcesItemProps } from "components/atoms/KeyResourcesItem";
import BottomSection from "components/organisms/BottomSection";
import { NewZigZag } from "components/molecules/NewZigZag";
import { VideoPlayerSection } from "components/molecules/VideoPlayerSection";
import { QuoteCarousel } from "components/organisms/QuoteCarousel";
import { Clouds } from "./Clouds";
import { color } from "@styles";
import { NewProductIntro } from "components/atoms/NewProductIntro";
import { useTranslations } from "next-intl";
import { StaticRoutes } from "@routes";
import { YULIFE_DATA } from "utils/_constants";
import { useLocale } from "@hooks";
import { LatestCaseStudy } from "utils/server";
import { ResponsiveZigZagImage } from "components/atoms/ResponsiveZigZagImage";
import Link from "next/link";
import { BetterButton } from "components/atoms/BetterButton";
import { CallbackAppOnly } from "components/molecules/CallbackAppOnly";
import { CenteredButtonWrap, VideoWrapper } from "./styles";
import { VideoPlayer } from "components/atoms/VideoPlayer";

interface AppProps {
  keyResources: KeyResourcesItemProps[];
  latestCaseStudy: LatestCaseStudy;
}

const App: FC<AppProps> = ({ keyResources, latestCaseStudy }) => {
  const { locale } = useLocale();
  const t = useTranslations("employee-app");

  return (
    <Page
      latestCaseStudy={latestCaseStudy}
      navColor="transparent"
      hero={
        <HeroSection
          eyeBrow="The YuLife Employee App"
          title="Serious about workplace wellbeing? Gamify it! "
          subHeading={t<string>("subtitle")}
          buttonText="Speak to our team"
          backgroundColor={color.S210}
          image={t<string>("image-path")}
          alt={t<string>("alt")}
          clouds={<Clouds />}
          imgPt="2rem"
          statBar={{
            stats: [
              {
                value: "93%",
                description: "highly engaged users",
                tooltip: "People who have downloaded the app in the last year and have been active in the last 30 days",
                marginRight: "-.5rem",
              },
              {
                value: "181%",
                description: "return on investment",
                tooltip: "The Economic Impact of YuLife, Forrester Consulting, November 2022.",
              },
              {
                value: "85%",
                description: "feel more productive",
                tooltip: "YuLife user survey, 2022",
              },
            ],
          }}
        />
      }
      fullScreenHero
    >
      <NewProductIntro
        title="Our game-changing approach your benefits"
        description=" Until now, your team didn’t love their insurance – or even know it existed. It’s time to
        rewrite these rules. Help protect your people for the future and inspire and reward them for
        living well today."
        backgroundColor="transparent"
        mobPb="2rem"
        pt="7.5rem"
        maxWidth="46rem"
      />
      <NewZigZag
        eyebrow={t<string>("eyebrow")}
        title="Inspire life with an app that people love – and use"
        description="Empower healthy living and ignite productivity. Reward and motivate your people to take small steps to transform their lifestyle for the better – at work, and at home."
        align="left"
        pt="2rem"
      >
        <ResponsiveZigZagImage
          src={t<string>("zigzag.inspire-life.file-path")}
          alt={t<string>("zigzag.inspire-life.description")}
        />
      </NewZigZag>
      <NewZigZag
        eyebrow="Rewards"
        title="Reward your people & protect the planet"
        description={t<string>("rewards.copy")}
        align="left"
        reverse
        linkUrl={StaticRoutes.featuresRewards}
        linkText="Find out more"
      >
        {locale === "us" ? (
          <VideoWrapper>
            <VideoPlayer mp4="/static/pages/insurance-carriers/yulife-us-rewards-screen.mp4" />
          </VideoWrapper>
        ) : (
          <ResponsiveZigZagImage
            src={t<string>("zigzag.reward-your-people.file-path")}
            alt={t<string>("zigzag.reward-your-people.description")}
          />
        )}
      </NewZigZag>
      <NewZigZag
        eyebrow="Benefits and support"
        title="Simplify your benefits with our Wellbeing Hub"
        description="Streamline benefits to immediately boost engagement. A one-stop-shop for all of your employee wellbeing, company benefits and perks – as easy to manage as it is to use."
        align="left"
        linkUrl={StaticRoutes.featuresWellbeingHub}
        linkText="Find out more"
      >
        <ResponsiveZigZagImage
          src={t<string>("zigzag.simplify-your-benefits.file-path")}
          alt={t<string>("zigzag.simplify-your-benefits.description")}
        />
      </NewZigZag>
      <NewZigZag
        eyebrow="Integration"
        title="Securely sync for a seamless experience"
        description="Create a seamless and engaging experience for you and your employees. The YuLife app integrates with popular wellbeing and health apps, including Fitbit, Garmin and Apple Health – as well as your other benefits, perks and wellness systems."
        align="left"
        reverse
        pb="5rem"
      >
        <ResponsiveZigZagImage
          src="/static/pages/app/yugi-wrapped-around-phone.svg"
          alt={t<string>("zigzag.securely-sync.description")}
        />
      </NewZigZag>
      <CenteredButtonWrap>
        <Link href={StaticRoutes.getAQuote}>
          <BetterButton text="Speak to our team" />
        </Link>
      </CenteredButtonWrap>
      <VideoPlayerSection
        title="It’s more than just insurance"
        videoImg="/static/img/VideoPlayer/VideoPlayerImg.jpg"
        altText="A still from a promotional YuLife video"
        vimeoId={t<string>("vimeo-id")}
      />
      {locale === "us" && <CallbackAppOnly />}
      <QuoteCarousel
        slides={[
          {
            quote:
              "I haven’t had a single employee not engage with the app. Everyone is walking to work to gain YuCoin!",
            caseStudyURL:
              "https://yulife.com/case-studies/yulife-inspires-healthy-performance-among-employees-at-vodafone-franchise-stores/",
            source: "Sol Zygadlo, Vodafone Partner Agent and Director, Vodafone",
          },
          {
            quote:
              "As soon as we launched it, our notifications went crazy! Everyone was counting their steps and talking about YuLife.",
            avatar: "/static/img/carousel/Alisha-Cohen.png",
            source: "Alisha Cohen, People Director, Vanti",
            vimeoId: locale !== "za" ? "679283779" : null,
            videoURL: locale === "za" ? "https://youtu.be/uu6clmKwBfk" : null,
          },
          {
            quote:
              "An amazing product that encourages you to live your best life. The support is second-to-none and the design of the app makes it easy to navigate.",
            avatar: "/static/img/carousel/jodie-capital-one.png",
            source: "Jodie Massingham, Benefits & Wellbeing Lead, Capital One",
            caseStudyURL: "https://yulife.com/case-studies/capital-one-onboards-yulife/",
          },
          {
            quote:
              "My poor gut health ignited me to make a lifestyle change, but the YuLife app was the fuel that carried me beyond my initial goals.",
            source:
              "Russell Taplin, Business Manager, Hodge Life Assurance & Omnilife Insurance, RGA",
          },
          {
            quote:
              "YuLife’s fun and interactive model of encouraging wellness is easy to engage with and has proved highly popular with our staff.",
            source: "Tom Conner, Director, Drewberry",
            caseStudyURL:
              "https://yulife.com/case-studies/insurance-broker-and-wealth-management-firm-drewberry-enjoys-employee-benefits-boost-from-yulife/",
          },
        ]}
      />
      <BottomSection
        keyResources={keyResources || []}
        requestCallbackProps={{ subtitle: t<string>("request-callback-subtitle") }}
      />
    </Page>
  );
};

export default App;
