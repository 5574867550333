import { Cloud } from "components/atoms/Cloud";
import { FC } from "react";

export const Clouds: FC = () => (
  <>
    <Cloud
      id="app-hero-cloud1"
      color="blue"
      scale={1.8}
      reversed
      top="18.42%"
      left="5.42%"
      animated
    />
    <Cloud id="app-hero-cloud2" color="blue" top="25.44%" right="10.69%" animated hideOnMobile />
    <Cloud
      id="app-hero-cloud3"
      color="blue"
      scale={0.8071}
      reversed
      top="29.82%"
      left="45.63%"
      animated
      hideOnMobile
    />
    <Cloud id="app-hero-cloud4" color="blue" scale={1.4} top="45.61%" right="20.14%" animated />
    <Cloud
      id="app-hero-cloud5"
      color="blue"
      scale={2.23}
      reversed
      bottom="22.81%"
      left="21.74%"
      animated
    />
  </>
);
